import { inProduction } from "../config";
import { inBrowser } from "./utils/utils";
import { captureEvent, captureException, setUser } from "@sentry/nextjs";
import { getOrg } from "./utils/site";
import { getClient } from "growthbook";

const MAX_RETRIES = 20;

type LogFunction = (event: string, props?: any) => Promise<any>;

let queue: Array<Parameters<LogFunction>> = [];
let interval: ReturnType<typeof window.setInterval> | null;
let retries = 0;

export const logEvent: LogFunction = async (event, props) => {
  if (!inBrowser) {
    return;
  }

  if (global.window.amplitude) {
    sendEventToAmplitude(event, props);
  } else {
    queue.push([event, props]);

    if (!interval && retries < MAX_RETRIES) {
      interval = setInterval(() => {
        retries += 1;

        if (retries >= MAX_RETRIES) {
          if (interval) {
            clearInterval(interval);
            interval = null;
          }
        }

        if (global.window.amplitude) {
          if (interval) {
            clearInterval(interval);
            interval = null;
          }

          queue.forEach(async (storedEvent) => {
            sendEventToAmplitude(...storedEvent);
          });

          queue = [];
        }
      }, 500);
    }
  }
};

const sendEventToAmplitude: LogFunction = async (event, props) => {
  if (inProduction) {
    if (global.window.amplitude) {
      await global.window.amplitude.logEvent(event, {
        ...(props ? props : {}),
        org: getOrg(),
      });
    } else {
      captureEvent({
        message: `Could not log event ${event}, due to amplitude not present`,
      });
    }
  } else {
    console.log("TRACK", event, { ...(props ? props : {}), org: getOrg() });
  }

  return Promise.resolve();
};

export const identify = async (
  id: string | undefined,
  props: any = {}
): Promise<any> => {
  const propsWithOrg = { ...props, org: getOrg() };
  if (inBrowser && inProduction) {
    const hotjar = global.window.hj;
    const amplitude = global.window.amplitude;

    if (hotjar) {
      hotjar("identify", id || props.resparkClientId, propsWithOrg);
    }
    if (amplitude) {
      if (id) {
        amplitude.setUserId(id);
      }
      const identifyObj = new amplitude.Identify();
      Object.keys(propsWithOrg)
        .filter((key) => !!propsWithOrg[key])
        .forEach((key) => {
          identifyObj.set(key, propsWithOrg[key]);
        });
      await amplitude.identify(identifyObj);
    }
    const gbClient = getClient();
    if (gbClient) {
      gbClient.setAttributes({ ...gbClient.getAttributes(), ...propsWithOrg });
    }
    try {
      id
        ? setUser({ id, ...propsWithOrg })
        : setUser({ id: propsWithOrg.resparkClientId, ...propsWithOrg });
    } catch (e) {
      captureException(e);
    }
  } else {
    console.log("Identify", id, propsWithOrg);
  }
  return Promise.resolve();
};

type CalendlyEvent = {
  event:
    | "calendly.profile_page_viewed"
    | "calendly.event_type_viewed"
    | "calendly.date_and_time_selected"
    | "calendly.event_scheduled";
  payload: any;
};

function isCalendlyEvent(e: MessageEvent<CalendlyEvent>) {
  return (
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.") === 0
  );
}

if (typeof window !== "undefined") {
  window.addEventListener(
    "message",
    function (event: MessageEvent<CalendlyEvent>) {
      if (isCalendlyEvent(event)) {
        logEvent(event.data.event);
      }
    }
  );
}
