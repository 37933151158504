import { Benefit, PackageConfig } from "./types";
import { ProductEnum } from "../../../../__generated__/globalTypes";
import { Deductible, Limit } from "../../../components-shared/insuranceData";
import { Org, getOrg } from "../../../utils/site";
import Colors from "ui-kit/colors";

export const sweBenefits: Benefit[] = [
  {
    name: "veterinaryCare",
    title: "insurance_packages.legend.veterinary_care.title",
    description: "insurance_packages.legend.veterinary_care.description",
  },
  {
    name: "medicine",
    title: "insurance_packages.legend.medicine.title",
    description: "insurance_packages.legend.medicine.description",
  },
  {
    name: "rehabilitation",
    title: "insurance_packages.legend.rehabilitation.title",
    description: "insurance_packages.legend.rehabilitation.description",
  },
  {
    name: "dentalDisease",
    title: "insurance_packages.legend.dental_disease.title",
    description: "insurance_packages.legend.dental_disease.description",
  },
  {
    name: "vetCalls",
    title: "insurance_packages.legend.vet_free_calls.title",
    description: "insurance_packages.legend.vet_free_calls.description",
  },
  {
    name: "hiddenFaults",
    title: "insurance_packages.legend.hidden_faults.title",
    description: "insurance_packages.legend.hidden_faults.description",
  },
  {
    name: "advancedDiagnostics",
    title: "insurance_packages.legend.advanced_diagnostics.title",
    description: "insurance_packages.legend.advanced_diagnostics.description",
  },
  {
    name: "milkTeeth",
    title: "insurance_packages.legend.milk_teeth.title",
    description: "insurance_packages.legend.milk_teeth.description",
  },
  {
    name: "radiation",
    title: "insurance_packages.legend.radiation.title",
    description: "insurance_packages.legend.radiation.description",
  },
  {
    name: "cesareanSection",
    title: "insurance_packages.legend.cesarean_section.title",
    description: "insurance_packages.legend.cesarean_section.description",
  },
  {
    name: "specialMeals",
    title: "insurance_packages.legend.special_meals.title",
    description: "insurance_packages.legend.special_meals.description",
  },
  {
    name: "deductible",
    title: "insurance_packages.legend.deductible.title",
    description: "insurance_packages.legend.deductible.description",
  },
  {
    name: "lifeInsurance",
    title: "insurance_packages.legend.life_insurance.title",
    description: "insurance_packages.legend.life_insurance.description",
  },
  {
    name: "breederInsurance",
    title: "insurance_packages.legend.breeder.title",
    description: "insurance_packages.legend.breeder.description",
  },
];

export const gerBenefits: Benefit[] = [
  {
    name: "surgery",
    title: "ger.insurance_packages.legend.operations.title",
    description: "ger.insurance_packages.legend.operations.description",
  },
  {
    name: "veterinaryCare",
    title: "ger.insurance_packages.legend.benefit_limit.title",
    description: "ger.insurance_packages.legend.benefit_limit.description",
  },
  {
    name: "preventiveBudget",
    title: "ger.insurance_packages.legend.annual_pension_budget.title",
    description:
      "ger.insurance_packages.legend.annual_pension_budget.description",
  },
  {
    name: "deductible",
    title: "ger.insurance_packages.legend.deductible.title",
    description: "ger.insurance_packages.legend.deductible.description",
  },
  {
    name: "castration",
    title: "ger.insurance_packages.legend.castration.title",
    description: "ger.insurance_packages.legend.castration.description",
  },
  {
    name: "behavioralTherapy",
    title: "ger.insurance_packages.legend.behavioral_therapy.title",
    description: "ger.insurance_packages.legend.behavioral_therapy.description",
  },
  {
    name: "animalPension",
    title: "ger.insurance_packages.legend.animal_pension.title",
    description: "ger.insurance_packages.legend.animal_pension.description",
  },
  {
    name: "accommodation",
    title: "ger.insurance_packages.legend.accommodation.title",
    description: "ger.insurance_packages.legend.accommodation.description",
  },
  {
    name: "vetCalls",
    title: "ger.insurance_packages.legend.free_vet_consultation.title",
    description:
      "ger.insurance_packages.legend.free_vet_consultation.description",
  },
  {
    name: "reimbursementFees",
    title: "ger.insurance_packages.legend.reimbursement_fees.title",
    description: "ger.insurance_packages.legend.reimbursement_fees.description",
  },
  {
    name: "scan",
    title: "ger.insurance_packages.legend.scan.title",
    description: "ger.insurance_packages.legend.scan.description",
  },
  {
    name: "advancedDiagnostics",
    title: "ger.insurance_packages.legend.special_diagnostics.title",
    description:
      "ger.insurance_packages.legend.special_diagnostics.description",
  },
  {
    name: "dentalDisease",
    title: "ger.insurance_packages.legend.dental_treatment.title",
    description: "ger.insurance_packages.legend.dental_treatment.description",
  },
  {
    name: "prosthesis",
    title: "ger.insurance_packages.legend.prosthesis.title",
    description: "ger.insurance_packages.legend.prosthesis.description",
  },
  {
    name: "foreignProtection",
    title: "ger.insurance_packages.legend.foreign_protection.title",
    description: "ger.insurance_packages.legend.foreign_protection.description",
  },
];

export const fraBenefits: Benefit[] = [
  {
    name: "veterinaryCare",
    title: "insurance_packages.legend.veterinary_care.title",
    description: "insurance_packages.legend.veterinary_care.description",
  },
  {
    name: "medicine",
    title: "insurance_packages.legend.medicine.title",
    description: "insurance_packages.legend.medicine.description",
  },
  {
    name: "surgery",
    title: "insurance_packages.legend.surgery.title",
    description: "insurance_packages.legend.surgery.description",
  },
  {
    name: "accommodation",
    title: "ger.insurance_packages.legend.accommodation.title",
    description: "ger.insurance_packages.legend.accommodation.description",
  },
  {
    name: "global",
    title: "ger.insurance_packages.legend.global.title",
    description: "ger.insurance_packages.legend.global.description",
  },
  {
    name: "animalPension",
    title: "ger.insurance_packages.legend.animal_pension.title",
    description: "ger.insurance_packages.legend.animal_pension.description",
  },
  {
    name: "dentalDisease",
    title: "insurance_packages.legend.dental_disease.title",
    description: "insurance_packages.legend.dental_disease.description",
  },
  {
    name: "advancedDiagnostics",
    title: "insurance_packages.legend.advanced_diagnostics.title",
    description: "insurance_packages.legend.advanced_diagnostics.description",
  },
  {
    name: "rehabilitation",
    title: "insurance_packages.legend.rehabilitation.title",
    description: "insurance_packages.legend.rehabilitation.description",
  },
  {
    name: "prosthesis",
    title: "ger.insurance_packages.legend.prosthesis.title",
    description: "ger.insurance_packages.legend.prosthesis.description",
  },
];

export const gerLiabilityBenefits: Benefit[] = [
  {
    name: "liabilityLimit",
    title: "ger.insurance_packages.legend.liability_sum.title",
    description: "ger.insurance_packages.legend.liability_sum.description",
  },
  {
    name: "liabilityDeductible",
    title: "ger.insurance_packages.legend.liability_deductible.title",
    description:
      "ger.insurance_packages.legend.liability_deductible.description",
  },
  {
    name: "liabilityRentalDamage",
    title: "ger.insurance_packages.legend.liability_rental_damage.title",
    description:
      "ger.insurance_packages.legend.liability_rental_damage.description",
  },
  {
    name: "liabilityOtherRentalDamage",
    title: "ger.insurance_packages.legend.liability_other_rental_damage.title",
    description:
      "ger.insurance_packages.legend.liability_other_rental_damage.description",
  },
  {
    name: "liabilityExcretionsDamage",
    title: "ger.insurance_packages.legend.liability_excretions_damage.title",
    description:
      "ger.insurance_packages.legend.liability_excretions_damage.description",
  },
  {
    name: "liabilityPuppiesCoinsurance",
    title: "ger.insurance_packages.legend.liability_puppies_coinsurance.title",
    description:
      "ger.insurance_packages.legend.liability_puppies_coinsurance.description",
  },
  {
    name: "liabilityCoverDamage",
    title: "ger.insurance_packages.legend.liability_cover_damage.title",
    description:
      "ger.insurance_packages.legend.liability_cover_damage.description",
  },
  {
    name: "liabilityInsuredPersons",
    title: "ger.insurance_packages.legend.liability_insured_persons.title",
    description:
      "ger.insurance_packages.legend.liability_insured_persons.description",
  },
  {
    name: "liabilityNoLeash",
    title: "ger.insurance_packages.legend.liability_no_leash.title",
    description: "ger.insurance_packages.legend.liability_no_leash.description",
  },
  {
    name: "liabilityRescueCosts",
    title: "ger.insurance_packages.legend.liability_resque_costs.title",
    description:
      "ger.insurance_packages.legend.liability_resque_costs.description",
  },
  {
    name: "liabilityActivitiesRisk",
    title: "ger.insurance_packages.legend.liability_activities_risk.title",
    description:
      "ger.insurance_packages.legend.liability_activities_risk.description",
  },
  {
    name: "liabilityAbroad",
    title: "ger.insurance_packages.legend.liability_abroad.title",
    description: "ger.insurance_packages.legend.liability_abroad.description",
  },
  {
    name: "liabilityAdditionalOptions",
    title: "ger.insurance_packages.legend.liability_additional_options.title",
    description:
      "ger.insurance_packages.legend.liability_additional_options.description",
  },
];

const packageConfigs: Record<Org, Array<PackageConfig>> = {
  swe: [
    {
      product: ProductEnum.cat,
      benefits: sweBenefits,
      packages: [
        {
          img: "/images/shields/cat-mini.svg",

          title: "insurance_packages.package.cat.mini.title",
          subTitle: "insurance_packages.package.cat.mini.sub_title",
          ipid: "insurance_packages.package.cat.mini.ipid",
          color: Colors.red100,
          tagline: "insurance_data.package.small.tagline",
          limit: 3000000 as Limit,
          benefitIncludes: {
            veterinaryCare:
              "insurance_packages.package.cat.mini.veterinary_care",
            vetCalls: true,
            hiddenFaults: true,
            dentalDisease: true,
            milkTeeth: false,
            advancedDiagnostics: false,
            radiation: false,
            medicine: false,
            cesareanSection: false,
            rehabilitation: false,
            specialMeals: false,
            deductible: "global.optional",
            lifeInsurance: "global.add_on",
            breederInsurance: "global.add_on",
          },
        },
        {
          img: "/images/shields/cat-midi.svg",
          popular: true,
          title: "insurance_packages.package.cat.between.title",
          subTitle: "insurance_packages.package.cat.between.sub_title",
          ipid: "insurance_packages.package.cat.between.ipid",
          color: Colors.sun200,
          tagline: "insurance_data.package.medium.tagline",
          limit: 6000000 as Limit,
          benefitIncludes: {
            veterinaryCare:
              "insurance_packages.package.cat.between.veterinary_care",
            vetCalls: true,
            hiddenFaults: true,
            dentalDisease: true,
            milkTeeth: true,
            advancedDiagnostics: true,
            radiation: true,
            medicine: "insurance_packages.package.cat.between.medicine",
            cesareanSection: true,
            rehabilitation:
              "insurance_packages.package.cat.between.rehabilitation",
            specialMeals:
              "insurance_packages.package.cat.between.special_meals",
            deductible: "global.optional",
            lifeInsurance: "global.add_on",
            breederInsurance: "global.add_on",
          },
        },
        {
          img: "/images/shields/cat-stor.svg",

          title: "insurance_packages.package.cat.big.title",
          subTitle: "insurance_packages.package.cat.big.sub_title",
          ipid: "insurance_packages.package.cat.big.ipid",
          color: Colors.forrest100,
          tagline: "insurance_data.package.large.tagline",
          limit: 16000000 as Limit,
          benefitIncludes: {
            veterinaryCare:
              "insurance_packages.package.cat.big.veterinary_care",
            vetCalls: true,
            hiddenFaults: true,
            dentalDisease: true,
            milkTeeth: true,
            advancedDiagnostics: true,
            radiation: true,
            medicine: "insurance_packages.package.cat.big.medicine",
            cesareanSection: true,
            rehabilitation: "insurance_packages.package.cat.big.rehabilitation",
            specialMeals: "insurance_packages.package.cat.big.special_meals",
            deductible: "insurance_packages.package.cat.big.deductible",
            lifeInsurance: "global.add_on",
            breederInsurance: "global.add_on",
          },
        },
      ],
    },
    {
      product: ProductEnum.dog,
      benefits: sweBenefits,
      packages: [
        {
          img: "/images/shields/dog-mini.svg",

          title: "insurance_packages.package.dog.mini.title",
          subTitle: "insurance_packages.package.dog.mini.sub_title",
          ipid: "insurance_packages.package.dog.mini.ipid",
          color: Colors.red100,
          tagline: "insurance_data.package.small.tagline",
          limit: 3000000 as Limit,
          benefitIncludes: {
            veterinaryCare:
              "insurance_packages.package.dog.mini.veterinary_care",
            vetCalls: true,
            hiddenFaults: true,
            dentalDisease: true,
            milkTeeth: false,
            advancedDiagnostics: false,
            radiation: false,
            medicine: false,
            cesareanSection: false,
            rehabilitation: false,
            specialMeals: false,
            deductible: "global.optional",
            lifeInsurance: "global.add_on",
            breederInsurance: "global.add_on",
          },
        },
        {
          img: "/images/shields/dog-midi.svg",
          popular: true,
          title: "insurance_packages.package.dog.between.title",
          subTitle: "insurance_packages.package.dog.between.sub_title",
          ipid: "insurance_packages.package.dog.between.ipid",
          color: Colors.sun200,
          tagline: "insurance_data.package.medium.tagline",
          limit: 6000000 as Limit,
          benefitIncludes: {
            veterinaryCare:
              "insurance_packages.package.dog.between.veterinary_care",
            vetCalls: true,
            hiddenFaults: true,
            dentalDisease: true,
            milkTeeth: true,
            advancedDiagnostics: true,
            radiation: true,
            medicine: "insurance_packages.package.dog.between.medicine",
            cesareanSection: true,
            rehabilitation:
              "insurance_packages.package.dog.between.rehabilitation",
            specialMeals:
              "insurance_packages.package.dog.between.special_meals",
            deductible: "global.optional",
            lifeInsurance: "global.add_on",
            breederInsurance: "global.add_on",
          },
        },
        {
          img: "/images/shields/dog-stor.svg",

          title: "insurance_packages.package.dog.big.title",
          subTitle: "insurance_packages.package.dog.big.sub_title",
          ipid: "insurance_packages.package.dog.big.ipid",
          color: Colors.forrest100,
          tagline: "insurance_data.package.large.tagline",
          limit: 16000000 as Limit,
          benefitIncludes: {
            veterinaryCare:
              "insurance_packages.package.dog.big.veterinary_care",
            vetCalls: true,
            hiddenFaults: true,
            dentalDisease: true,
            milkTeeth: true,
            advancedDiagnostics: true,
            radiation: true,
            medicine: "insurance_packages.package.dog.big.medicine",
            cesareanSection: true,
            rehabilitation: "insurance_packages.package.dog.big.rehabilitation",
            specialMeals: "insurance_packages.package.dog.big.special_meals",
            deductible: "global.optional",
            lifeInsurance: "global.add_on",
            breederInsurance: "global.add_on",
          },
        },
      ],
    },
  ],
  ger: [
    {
      product: ProductEnum.cat,
      benefits: gerBenefits,
      packages: [
        {
          img: "/images/shields/cat-op.svg",
          ipid: "ger.insurance_packages.package.cat.op.ipid",
          benefitIncludes: {
            vetCalls:
              "ger.insurance_packages.package.cat.op.free_vet_consultation",
            deductible: "ger.insurance_packages.package.cat.op.deductible",
            surgery: "ger.insurance_packages.package.cat.op.operations",
            veterinaryCare: false,
            preventiveBudget: false,
            advancedDiagnostics:
              "ger.insurance_packages.package.cat.op.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.cat.op.dental_treatment",
            reimbursementFees: true,
            accommodation:
              "ger.insurance_packages.package.cat.op.accommodation",
            foreignProtection: true,
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            castration: false,
            behavioralTherapy: false,
            animalPension: false,
          },
          color: Colors.grey100,
          limit: 0 as Limit,
          title: "ger.insurance_packages.package.cat.op.title",
          subTitle: "ger.insurance_packages.package.cat.op.sub_title",
          compactText: "ger.insurance_packages.package.cat.op.compact_text",
        },
        {
          img: "/images/shields/cat-mini.svg",
          ipid: "ger.insurance_packages.package.cat.mini.ipid",
          benefitIncludes: {
            vetCalls: true,
            deductible: "ger.insurance_packages.package.cat.mini.deductible",
            surgery: "ger.insurance_packages.package.cat.mini.operations",
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            veterinaryCare:
              "ger.insurance_packages.package.cat.mini.benefit_limit",
            preventiveBudget:
              "ger.insurance_packages.package.cat.mini.annual_pension_budget",
            advancedDiagnostics:
              "ger.insurance_packages.package.cat.mini.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.cat.mini.dental_treatment",
            reimbursementFees: true,
            accommodation: true,
            animalPension:
              "ger.insurance_packages.package.cat.mini.animal_pension",
            foreignProtection: true,
            castration: false,
            behavioralTherapy: false,
          },
          color: Colors.red100,
          limit: 300000 as Limit,
          title: "ger.insurance_packages.package.cat.mini.title",
          subTitle: "ger.insurance_packages.package.cat.mini.sub_title",
          compactText: "ger.insurance_packages.package.cat.mini.compact_text",
        },
        {
          popular: true,
          img: "/images/shields/cat-midi.svg",
          ipid: "ger.insurance_packages.package.cat.midi.ipid",
          benefitIncludes: {
            vetCalls: true,
            deductible: "ger.insurance_packages.package.cat.midi.deductible",
            surgery: "ger.insurance_packages.package.cat.midi.operations",
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            veterinaryCare:
              "ger.insurance_packages.package.cat.midi.benefit_limit",
            preventiveBudget:
              "ger.insurance_packages.package.cat.midi.annual_pension_budget",
            advancedDiagnostics:
              "ger.insurance_packages.package.cat.midi.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.cat.midi.dental_treatment",
            reimbursementFees: true,
            accommodation: true,
            animalPension:
              "ger.insurance_packages.package.cat.midi.animal_pension",
            foreignProtection: true,
            castration: false,
            behavioralTherapy: false,
          },
          color: Colors.sun200,
          limit: 100000 as Limit,
          title: "ger.insurance_packages.package.cat.midi.title",
          subTitle: "ger.insurance_packages.package.cat.midi.sub_title",
          compactText: "ger.insurance_packages.package.cat.midi.compact_text",
        },
        {
          img: "/images/shields/cat-stor.svg",
          ipid: "ger.insurance_packages.package.cat.maxi.ipid",
          benefitIncludes: {
            vetCalls: true,
            deductible: "ger.insurance_packages.package.cat.maxi.deductible",
            surgery: "ger.insurance_packages.package.cat.maxi.operations",
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            veterinaryCare:
              "ger.insurance_packages.package.cat.maxi.benefit_limit",
            preventiveBudget:
              "ger.insurance_packages.package.cat.maxi.annual_pension_budget",
            castration: "ger.insurance_packages.package.cat.maxi.castration",
            behavioralTherapy:
              "ger.insurance_packages.package.cat.maxi.behavioral_therapy",
            advancedDiagnostics:
              "ger.insurance_packages.package.cat.maxi.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.cat.maxi.dental_treatment",
            reimbursementFees: true,
            accommodation: true,
            animalPension:
              "ger.insurance_packages.package.cat.maxi.animal_pension",
            foreignProtection: true,
          },
          color: Colors.forrest100,
          limit: 9999999900 as Limit,
          title: "ger.insurance_packages.package.cat.maxi.title",
          subTitle: "ger.insurance_packages.package.cat.maxi.sub_title",
          compactText: "ger.insurance_packages.package.cat.maxi.compact_text",
        },
      ],
    },
    {
      product: ProductEnum.dog,
      benefits: gerBenefits,
      packages: [
        {
          img: "/images/shields/dog-op.svg",
          ipid: "ger.insurance_packages.package.dog.op.ipid",
          benefitIncludes: {
            vetCalls:
              "ger.insurance_packages.package.dog.op.free_vet_consultation",
            deductible: "ger.insurance_packages.package.dog.op.deductible",
            surgery: "ger.insurance_packages.package.dog.op.operations",
            veterinaryCare: false,
            preventiveBudget: false,
            advancedDiagnostics:
              "ger.insurance_packages.package.dog.op.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.dog.op.dental_treatment",
            reimbursementFees: true,
            accommodation:
              "ger.insurance_packages.package.dog.op.accommodation",
            foreignProtection: true,
            castration: false,
            behavioralTherapy: false,
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            animalPension: false,
          },
          color: Colors.grey100,
          limit: 0 as Limit,
          title: "ger.insurance_packages.package.dog.op.title",
          subTitle: "ger.insurance_packages.package.dog.op.sub_title",
          compactText: "ger.insurance_packages.package.dog.op.compact_text",
        },
        {
          img: "/images/shields/dog-mini.svg",
          ipid: "ger.insurance_packages.package.dog.mini.ipid",
          benefitIncludes: {
            vetCalls: true,
            deductible: "ger.insurance_packages.package.dog.mini.deductible",
            surgery: "ger.insurance_packages.package.dog.mini.operations",
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            veterinaryCare:
              "ger.insurance_packages.package.dog.mini.benefit_limit",
            preventiveBudget:
              "ger.insurance_packages.package.dog.mini.annual_pension_budget",
            advancedDiagnostics:
              "ger.insurance_packages.package.dog.mini.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.dog.mini.dental_treatment",
            reimbursementFees: true,
            accommodation: true,
            animalPension:
              "ger.insurance_packages.package.dog.mini.animal_pension",
            foreignProtection: true,
            castration: false,
            behavioralTherapy: false,
          },
          color: Colors.red100,
          limit: 300000 as Limit,
          title: "ger.insurance_packages.package.dog.mini.title",
          subTitle: "ger.insurance_packages.package.dog.mini.sub_title",
          compactText: "ger.insurance_packages.package.dog.mini.compact_text",
        },
        {
          popular: true,
          img: "/images/shields/dog-midi.svg",
          ipid: "ger.insurance_packages.package.dog.midi.ipid",
          benefitIncludes: {
            vetCalls: true,
            deductible: "ger.insurance_packages.package.dog.midi.deductible",
            surgery: "ger.insurance_packages.package.dog.midi.operations",
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            veterinaryCare:
              "ger.insurance_packages.package.dog.midi.benefit_limit",
            preventiveBudget:
              "ger.insurance_packages.package.dog.midi.annual_pension_budget",
            advancedDiagnostics:
              "ger.insurance_packages.package.dog.midi.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.dog.midi.dental_treatment",
            reimbursementFees: true,
            accommodation: true,
            animalPension:
              "ger.insurance_packages.package.dog.midi.animal_pension",
            foreignProtection: true,
            castration: false,
            behavioralTherapy: false,
          },
          color: Colors.sun200,
          limit: 100000 as Limit,
          title: "ger.insurance_packages.package.dog.midi.title",
          subTitle: "ger.insurance_packages.package.dog.midi.sub_title",
          compactText: "ger.insurance_packages.package.dog.midi.compact_text",
        },
        {
          img: "/images/shields/dog-stor.svg",
          ipid: "ger.insurance_packages.package.dog.maxi.ipid",
          benefitIncludes: {
            vetCalls: true,
            deductible: "ger.insurance_packages.package.dog.maxi.deductible",
            surgery: "ger.insurance_packages.package.dog.maxi.operations",
            scan: true,
            prosthesis: "ger.insurance_packages.package.all.prosthesis",
            veterinaryCare:
              "ger.insurance_packages.package.dog.maxi.benefit_limit",
            preventiveBudget:
              "ger.insurance_packages.package.dog.maxi.annual_pension_budget",
            castration: "ger.insurance_packages.package.dog.maxi.castration",
            behavioralTherapy:
              "ger.insurance_packages.package.dog.maxi.behavioral_therapy",
            advancedDiagnostics:
              "ger.insurance_packages.package.dog.maxi.special_diagnostics",
            dentalDisease:
              "ger.insurance_packages.package.dog.maxi.dental_treatment",
            reimbursementFees: true,
            accommodation: true,
            animalPension:
              "ger.insurance_packages.package.dog.maxi.animal_pension",
            foreignProtection: true,
          },
          color: Colors.forrest100,
          limit: 9999999900 as Limit,
          title: "ger.insurance_packages.package.dog.maxi.title",
          subTitle: "ger.insurance_packages.package.dog.maxi.sub_title",
          compactText: "ger.insurance_packages.package.dog.maxi.compact_text",
        },
      ],
    },
    {
      product: ProductEnum.liability,
      benefits: gerLiabilityBenefits,
      packages: [
        {
          img: "/images/shields/liability.svg",
          ipid: "ger.insurance_packages.package.dog.liability.ipid",
          benefitIncludes: {
            liabilityLimit:
              "ger.insurance_packages.package.dog.liability.liability_sum",
            liabilityDeductible:
              "ger.insurance_packages.package.dog.liability.liability_deductible",
            liabilityRentalDamage: true,
            liabilityOtherRentalDamage:
              "ger.insurance_packages.package.dog.liability.liability_other_rental_damage",
            liabilityExcretionsDamage: true,
            liabilityPuppiesCoinsurance:
              "ger.insurance_packages.package.dog.liability.liability_puppies_coinsurance",
            liabilityCoverDamage: true,
            liabilityInsuredPersons:
              "ger.insurance_packages.package.dog.liability.liability_insured_persons",
            liabilityNoLeash: true,
            liabilityRescueCosts:
              "ger.insurance_packages.package.dog.liability.liability_resque_costs",
            liabilityActivitiesRisk:
              "ger.insurance_packages.package.dog.liability.liability_activities_risk",
            liabilityAbroad:
              "ger.insurance_packages.package.dog.liability.liability_abroad",
            liabilityAdditionalOptions:
              "ger.insurance_packages.package.dog.liability.liability_additional_options",
          },
          color: Colors.grey600,
          limit: 1000000000 as Limit,
          deductible: 15000 as Deductible,
          title: "ger.insurance_packages.package.dog.liability.title",
          subTitle: "ger.insurance_packages.package.dog.liability.sub_title",
          tagline: "ger.insurance_packages.package.dog.liability.tagline",
        },
        {
          img: "/images/shields/liability-plus.svg",
          ipid: "ger.insurance_packages.package.dog.liability_plus.ipid",
          benefitIncludes: {
            liabilityLimit:
              "ger.insurance_packages.package.dog.liability.liability_sum",
            liabilityDeductible:
              "ger.insurance_packages.package.dog.liability_plus.liability_deductible",
            liabilityRentalDamage: true,
            liabilityOtherRentalDamage:
              "ger.insurance_packages.package.dog.liability.liability_other_rental_damage",
            liabilityExcretionsDamage: true,
            liabilityPuppiesCoinsurance:
              "ger.insurance_packages.package.dog.liability.liability_puppies_coinsurance",
            liabilityCoverDamage: true,
            liabilityInsuredPersons:
              "ger.insurance_packages.package.dog.liability.liability_insured_persons",
            liabilityNoLeash: true,
            liabilityRescueCosts:
              "ger.insurance_packages.package.dog.liability.liability_resque_costs",
            liabilityActivitiesRisk:
              "ger.insurance_packages.package.dog.liability.liability_activities_risk",
            liabilityAbroad:
              "ger.insurance_packages.package.dog.liability.liability_abroad",
            liabilityAdditionalOptions:
              "ger.insurance_packages.package.dog.liability.liability_additional_options",
          },
          color: Colors.green100,
          limit: 1000000000 as Limit,
          deductible: 0 as Deductible,
          title: "ger.insurance_packages.package.dog.liability_plus.title",
          subTitle:
            "ger.insurance_packages.package.dog.liability_plus.sub_title",
          tagline: "ger.insurance_packages.package.dog.liability.tagline",
        },
      ],
    },
  ],
  fra: [
    {
      product: ProductEnum.cat,
      benefits: fraBenefits,
      packages: [
        {
          img: "/images/shields/cat-op.svg",

          title: "insurance_packages.package.cat.mini.title",
          subTitle: "insurance_packages.package.cat.mini.sub_title",
          ipid: "fra.insurance_packages.package.ipid",
          color: Colors.red100,
          tagline: "insurance_data.package.small.tagline",
          limit: 100000 as Limit,
          benefitIncludes: {
            veterinaryCare: "insurance_packages.package.small.veterinary_care",
            medicine: true,
            surgery: true,
            accommodation: true,
            advancedDiagnostics: true,
            dentalDisease: true,
            rehabilitation: true,
            prosthesis: true,
            animalPension: "insurance_packages.package.animal_pension",
            global: true,
          },
        },
        {
          img: "/images/shields/cat-mini.svg",
          popular: true,
          title: "insurance_packages.package.cat.between.title",
          subTitle: "insurance_packages.package.cat.between.sub_title",
          ipid: "fra.insurance_packages.package.ipid",
          color: Colors.sun200,
          tagline: "insurance_data.package.medium.tagline",
          limit: 200000 as Limit,
          benefitIncludes: {
            veterinaryCare: "insurance_packages.package.medium.veterinary_care",
            medicine: true,
            surgery: true,
            accommodation: true,
            advancedDiagnostics: true,
            dentalDisease: true,
            rehabilitation: true,
            prosthesis: true,
            animalPension: "insurance_packages.package.animal_pension",
            global: true,
          },
        },
        {
          img: "/images/shields/cat-midi.svg",
          title: "insurance_packages.package.cat.big.title",
          subTitle: "insurance_packages.package.cat.big.sub_title",
          ipid: "fra.insurance_packages.package.ipid",
          color: Colors.forrest100,
          tagline: "insurance_data.package.large.tagline",
          limit: 300000 as Limit,
          benefitIncludes: {
            veterinaryCare: "insurance_packages.package.large.veterinary_care",
            medicine: true,
            surgery: true,
            accommodation: true,
            advancedDiagnostics: true,
            dentalDisease: true,
            rehabilitation: true,
            prosthesis: true,
            animalPension: "insurance_packages.package.animal_pension",
            global: true,
          },
        },
      ],
    },
    {
      product: ProductEnum.dog,
      benefits: fraBenefits,
      packages: [
        {
          img: "/images/shields/dog-op.svg",

          title: "insurance_packages.package.dog.mini.title",
          subTitle: "insurance_packages.package.dog.mini.sub_title",
          ipid: "fra.insurance_packages.package.ipid",
          color: Colors.red100,
          tagline: "insurance_data.package.small.tagline",
          limit: 100000 as Limit,
          benefitIncludes: {
            veterinaryCare: "insurance_packages.package.small.veterinary_care",
            medicine: true,
            surgery: true,
            accommodation: true,
            advancedDiagnostics: true,
            dentalDisease: true,
            rehabilitation: true,
            prosthesis: true,
            animalPension: "insurance_packages.package.animal_pension",
            global: true,
          },
        },
        {
          img: "/images/shields/dog-mini.svg",
          popular: true,
          popularColor: Colors.forrest300,
          title: "insurance_packages.package.dog.between.title",
          subTitle: "insurance_packages.package.dog.between.sub_title",
          ipid: "fra.insurance_packages.package.ipid",
          color: Colors.forrest100,
          tagline: "insurance_data.package.medium.tagline",
          limit: 200000 as Limit,
          benefitIncludes: {
            veterinaryCare: "insurance_packages.package.medium.veterinary_care",
            medicine: true,
            surgery: true,
            accommodation: true,
            advancedDiagnostics: true,
            dentalDisease: true,
            rehabilitation: true,
            prosthesis: true,
            animalPension: "insurance_packages.package.animal_pension",
            global: true,
          },
        },
        {
          img: "/images/shields/dog-midi.svg",

          title: "insurance_packages.package.dog.big.title",
          subTitle: "insurance_packages.package.dog.big.sub_title",
          ipid: "fra.insurance_packages.package.ipid",
          color: Colors.sun200,
          tagline: "insurance_data.package.large.tagline",
          limit: 300000 as Limit,
          benefitIncludes: {
            veterinaryCare: "insurance_packages.package.large.veterinary_care",
            medicine: true,
            surgery: true,
            accommodation: true,
            advancedDiagnostics: true,
            dentalDisease: true,
            rehabilitation: true,
            prosthesis: true,
            animalPension: "insurance_packages.package.animal_pension",
            global: true,
          },
        },
      ],
    },
  ],
};

export const getPackageConfig = ({
  product,
}: {
  product: ProductEnum;
}): PackageConfig | null => {
  return (
    packageConfigs[getOrg()].find((config) => config.product === product) ??
    null
  );
};
