import Image from "next/image";

import styles from "./Benefits.module.css";
import { Typography } from "../../../../ui-kit/Typography";
import { FC } from "react";
import { useWebTranslation } from "../../../../translations";
import { Org, getOrg } from "../../../../utils/site";

type BenefitsProps = {};

const benefits: Record<Org, Array<{ src: string; text: string }>> = {
  fra: [],
  ger: [
    {
      src: "/images/benefits/clap.svg",
      text: "benefits.benefit.friendly",
    },
    {
      src: "/images/benefits/flash.svg",
      text: "benefits.benefit.claim",
    },
    {
      src: "/images/benefits/paw.svg",
      text: "benefits.benefit.pet",
    },
    {
      src: "/images/benefits/phone.svg",
      text: "benefits.benefit.app",
    },
    {
      src: "/images/benefits/percent.svg",
      text: "benefits.benefit.discount",
    },
    {
      src: "/images/benefits/knowledge.svg",
      text: "benefits.benefit.knowledge",
    },
  ],
  swe: [
    {
      src: "/images/benefits/clap.svg",
      text: "benefits.benefit.friendly",
    },
    {
      src: "/images/benefits/phone.svg",
      text: "benefits.benefit.firstvet",
    },
    {
      src: "/images/benefits/knowledge.svg",
      text: "benefits.benefit.knowledge",
    },
    {
      src: "/images/benefits/star.svg",
      text: "benefits.benefit.star",
    },
    {
      src: "/images/benefits/scale.svg",
      text: "benefits.benefit.switch",
    },
    {
      src: "/images/benefits/paw.svg",
      text: "benefits.benefit.pet",
    },
  ],
};

const Benefits: FC<BenefitsProps> = () => {
  const { t } = useWebTranslation();

  return (
    <div className={styles.benefits}>
      {benefits[getOrg()].map((benefit) => (
        <div key={benefit.src} className={styles.benefit}>
          <Image src={benefit.src} alt="Benefit" width={48} height={48} />
          <Typography className={styles.benefitText}>
            {t(benefit.text)}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Benefits;
