import { FC } from "react";
import Image from "next/image";
import styles from "./PackageCard.module.css";
import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import { Typography } from "ui-kit/Typography";
import Gutter from "ui-kit/Gutter";
import { useWebTranslation } from "translations";
import { Package } from "components/ContentfulRenderer/ContentfulPackages/types";
import classNames from "classnames";
import { formatCurrency } from "utils/utils";
import PackageCardTab from "./PackageCardTab";
import { getCurrencyCode } from "utils/site";
import { AmountToPay } from "utils/insurance/getAmountToPay";

interface PackageCardProps {
  pack: Package;
  amountToPay?: AmountToPay;
  rounded?: boolean;
}

const PackageCard: FC<PackageCardProps> = ({ pack, amountToPay, rounded }) => {
  const { t } = useWebTranslation();

  const { title, subTitle, img, color, popular, popularColor } = pack;

  return (
    <FlexCol justifyContent="flex-end">
      {popular && (
        <PackageCardTab text={t("global.most_popular")} color={popularColor} />
      )}
      <FlexRow
        className={classNames(
          styles.container,
          rounded && styles.rounded,
          popular && styles.popular
        )}
        alignItems="flex-start"
        style={{
          backgroundColor: color,
        }}
      >
        <Image
          src={img}
          alt={t(title)}
          className={styles.image}
          width={42}
          height={50}
        />

        <FlexCol className={styles.text} justifyContent="center">
          <FlexRow justifyContent="space-between" alignItems="center">
            <FlexRow alignItems="center" style={{ gap: 8, minHeight: 28 }}>
              <Typography variant="h4">{t(title)}</Typography>
            </FlexRow>
            {amountToPay ? (
              <Typography variant="h4">
                {formatCurrency(
                  amountToPay.old ?? amountToPay.new,
                  getCurrencyCode()
                )}
              </Typography>
            ) : null}
          </FlexRow>
          <Gutter size={0.5} />
          <Typography variant="body3" style={{ margin: 0 }}>
            {t(subTitle)}
          </Typography>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};

export default PackageCard;
